<template>
  <svg
    v-once
    width="16"
    height="16"
    viewBox="0 0 35533 32000"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient id="a" x2="17767" y1="18388" y2="18388" gradientUnits="userSpaceOnUse">
        <stop offset="0" stop-color="#f5c948"/>
        <stop offset="1" stop-color="#dea61b"/>
      </linearGradient>
      <linearGradient id="b" x1="24455" x2="24455" y2="31990" gradientUnits="userSpaceOnUse">
        <stop offset="0" stop-color="#31bc41"/>
        <stop offset="1" stop-color="#169930"/>
      </linearGradient>
    </defs>
    <path d="M17760 22627 0 4777 10835 32000l6932-9430" fill="url(#a)"/>
    <path d="m13376 16587 5088-6707h-60l4547 5678L35533 0l-6991 31990-15164-15465" fill="url(#b)"/>
  </svg>
</template>
